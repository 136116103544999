<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-2">
      <h2 class="text-lg font-medium mr-auto">Honorarium</h2>
      <router-link class="btn btn-primary" :to="{ name: 'create-formathonor' }"
        ><PlusCircleIcon class="mr-1 h-5"></PlusCircleIcon> Buat
        Honorarium</router-link
      >
    </div>

    <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
    <AlertFailed v-if="alertFailed" :message="messageAlert" />

    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
          id="tabulator-html-filter-form"
          class="xl:flex sm:mr-auto"
          @submit.prevent="onFilter"
        >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Tahun</label
            >

            <select
              v-model="insTahun"
              class="form-select mr-2"
              @change="setTahunBiaya"
            >
              <option
                v-for="(tahun, index) in listTahun"
                :key="index"
                :value="tahun"
              >
                {{ tahun }}
              </option>
            </select>
            <select
              v-model="insKelompok"
              class="form-select mr-2"
              @change="setKelompokBiaya"
            >
              <option
                v-for="item in listKelompok"
                :key="item.id"
                :value="item.value"
                :selected="item.selected"
              >
                {{ item.text }}
              </option>
            </select>
          </div>

          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mx-2"
              @click="onReload"
            >
              RESET
            </button>
          </div>
          <div v-if="insTahun" class="mt-2 xl:mt-0 w-36">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-dark w-full mx-2"
              @click="duplicateFormat"
            >
              <CopyIcon class="w-5 mr-1"></CopyIcon> Duplikat {{ insTahun }}
            </button>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { tblTabulator } from "@/utils/tabulator";
import { HTTPKEU } from "../../../services/http";
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
import AlertFailed from "../../../components/alerts/AlertFailed.vue";
import router from "@/router";
import { keuangan } from "../../../services/models";

export default defineComponent({
  components: {
    AlertSuccess,
    AlertFailed,
  },
  setup() {
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const idBeasiswa = ref();
    const alertSuccess = ref();
    const alertFailed = ref();
    const messageAlert = ref();
    const filter = reactive({
      field: "kode",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];

    const editRow = (e, cell) => {
      idBeasiswa.value = cell.getData()?.id;
      alertSuccess.value = false;
      alertFailed.value = false;
      messageAlert.value = "";
      router.push({
        name: "edit-formathonor",
        params: { id: idBeasiswa.value },
      });
    };

    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "Thn Anggaran",
          field: "tahun_anggaran",
          width: 150,
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Angka Kredit",
          field: "jabfung.angka_kredit",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Kelompok",
          field: "master_kelompok_honor.ket_kelompok_honor",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Waktu",
          field: "kelompok_kelas",
          width: 150,
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          formatter(cell) {
            let listwaktu = [
              { kode: "P", nama: "Pagi" },
              { kode: "S", nama: "Sore" },
              { kode: "K", nama: "Kelas Karyawan" },
            ];

            if (cell.getData().kelompok_kelas) {
              let valFil = listwaktu.filter((fil) => {
                return fil.kode == cell.getData().kelompok_kelas;
              });
              return valFil[0].nama;
            } else {
              return "";
            }
          },
        },
        {
          title: "Nominal",
          field: "nominal",
          hozAlign: "right",
          vertAlign: "top",
          print: true,
          download: true,
          formatter(cell) {
            let rupiahLocal = Intl.NumberFormat("id-ID");
            return "Rp. " + rupiahLocal.format(cell.getData().nominal);
          },
        },
        {
          title: "#",
          field: "id",
          width: 100,
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let routeData = router.resolve({
              name: "edit-formathonor",
              params: { id: cell.getData().id },
            });

            return `
              <div>
                <a class="flex space-x-1 items-center btn btn-sm btn-warning" href="${routeData.href}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14px" fill="#000"><path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg> Edit
                </a>
              </div>`;
          },
          cellClick: editRow,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "master/honorarium",
        columns
      );
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "kode";
      filter.type = "=";
      filter.value = "";
      onFilter();
    };
    onMounted(() => {
      initTabulator();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      accessToken,
      idBeasiswa,
      alertSuccess,
      alertFailed,
      messageAlert,
    };
  },
  data() {
    return {
      insTahun: this.$store.state.main.currentTahunBiaya,
      insKelompok: this.$store.state.main.currentKelompokBiaya,
      listTahun: [],
      listKelompok: [],
    };
  },
  mounted() {
    this.gettahunlist();
    this.getKelompokHonor();
  },
  methods: {
    resetAlert() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.messageAlert = "";
    },
    onReload() {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "master/honorarium",
        {},
        tblTabulator.ajaxConfig()
      );
    },
    gettahunlist() {
      let nowYear = new Date().getFullYear();
      // eslint-disable-next-line for-direction
      for (let i = nowYear + 1; i > nowYear - 8; i--) {
        this.listTahun.push(i);
      }
    },
    setTahunBiaya() {
      this.$store.dispatch("main/currentTahunBiaya", this.insTahun);
      this.onReload();
    },
    setKelompokBiaya() {
      this.$store.dispatch("main/currentKelompokBiaya", this.insKelompok);
      this.onReload();
    },
    getKelompokHonor() {
      this.listKelompok = [];
      let opt = {
        id: 0,
        value: "",
        text: "Pilih Kelompok Honor",
        selected: true,
      };
      this.listKelompok.push(opt);
      HTTPKEU("list/kelompok-honor").then((res) => {

        res.data.forEach((item) => {
          const opt = {
            id: item.id,
            value: item.id,
            text: item.ket_kelompok_honor,
            selected: false,
          };
          this.listKelompok.push(opt);
        });
      });
    },
    duplicateFormat() {
      keuangan.Honorarium.postCustomPath("duplicateFormat").then((res) => {
        this.resetAlert();
        if(res.data.code === 200){
          this.alertSuccess = true;
          this.messageAlert = res.data.message;
        }
      });
    },
  },
});
</script>
