<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-2">
      <h2 class="text-lg font-medium mr-auto">Daftar Akun</h2>
      <router-link class="btn btn-primary" :to="{ name: 'create-akun' }"
        ><PlusCircleIcon class="mr-1 h-5"></PlusCircleIcon> Buat
        Akun</router-link
      >
    </div>

    <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
    <AlertFailed v-if="alertFailed" :message="messageAlert" />

    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
          id="tabulator-html-filter-form"
          class="xl:flex sm:mr-auto"
          @submit.prevent="onFilter"
        >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="kd_account">Kode Akun</option>
              <option value="account">Nama Akun</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { tblTabulator } from "@/utils/tabulator";
import AlertSuccess from "../../../components/alerts/AlertSuccess.vue";
import AlertFailed from "../../../components/alerts/AlertFailed.vue";
import router from "@/router";

export default defineComponent({
  components: {
    AlertSuccess,
    AlertFailed,
  },
  setup() {
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const idBeasiswa = ref();
    const alertSuccess = ref();
    const alertFailed = ref();
    const messageAlert = ref();
    const filter = reactive({
      field: "kd_account",
      type: "=",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];

    const editRow = (e, cell) => {
      idBeasiswa.value = cell.getData()?.id;
      alertSuccess.value = false;
      alertFailed.value = false;
      messageAlert.value = "";
      router.push({
        name: "edit-akun",
        params: { id: idBeasiswa.value },
      });
    };

    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "Kode Akun",
          field: "kd_account",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Nama Akun",
          field: "account",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Status",
          width: 200,
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          formatter(cell) {
            console.log(cell);
            return cell.getData().status === "D"
              ? '<div class="rounded-full text-center text-xs px-3 py-1 bg-theme-9 text-white font-small">D</div>'
              : '<div class="rounded-full text-center text-xs px-3 py-1 bg-theme-6 text-white font-small">-</div>';
          },
        },
        {
          title: "#",
          field: "id",
          responsive: 1,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let routeData = router.resolve({
              name: "edit-akun",
              params: { id: cell.getData().kd_account },
            });

            return `
              <div>
                <a class="flex space-x-1 items-center btn btn-sm btn-warning" href="${routeData.href}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14px" fill="#000"><path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg> Edit
                </a>
              </div>`;
          },
          cellClick: editRow,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "master/akun",
        columns
      );
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "kd_account";
      filter.type = "=";
      filter.value = "";
      onFilter();
    };
    onMounted(() => {
      initTabulator();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      accessToken,
      idBeasiswa,
      alertSuccess,
      alertFailed,
      messageAlert,
    };
  },
});
</script>
