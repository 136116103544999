<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Master Keuangan Univ MDP <br /><span class="text-sm font-medium"
          >Master biaya keuangan untuk Mahasiswa tiap Semester dan Tahun
          Akademik</span
        >
      </h2>
      <router-link
        class="btn btn-primary"
        :to="{ name: 'create-master-keuangan' }"
        ><PlusCircleIcon class="mr-1 h-5"></PlusCircleIcon> Buat Biaya
        Kuliah</router-link
      >
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="hasFeatures('config')"
          :to="{ name: 'config' }"
          class="btn btn-default mb-3 md:ml-2 md:mt-0 mt-2"
        >
          <ToolIcon class="w-4 mr-2" />
          Pengaturan
        </router-link>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <LoadingSpinner :is-loading="loadingStatus" />
      <DeletingStatus :is-deleting="deleting" />

      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form
          id="tabulator-html-filter-form"
          class="xl:flex sm:mr-auto"
          @submit.prevent="onFilter"
        >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Tahun</label
            >

            <select
              v-model="insTahun"
              class="form-select mr-2"
              @change="setTahunBiaya"
            >
              <option
                v-for="(tahun, index) in listTahun"
                :key="index"
                :value="tahun"
              >
                {{ tahun }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Jenis Biaya</label
            >

            <select
              v-model="insJenis"
              class="form-select mr-2"
              @change="setJenisBiaya"
            >
              <option
                v-for="(jenis, index) in listJenis"
                :key="index"
                :value="jenis.value"
              >
                {{ jenis.text }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4">
            <select
              v-model="insFakultas"
              class="form-select"
              @change="setFilFakultas"
            >
              <option v-for="fak in faculties" :key="fak.id" :value="fak.value">
                {{ fak.text }}
              </option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="sub_jenis_waktu">Waktu</option>
              <option value="sub_jenis_sumbangan">Jenis Sumbangan</option>
              <option value="sub_jenis_pendaftaran">Jalur Daftar</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <select
              v-if="filter.field == 'sub_jenis_waktu'"
              v-model="filter.value"
              class="form-select"
            >
              <option value="P">Pagi</option>
              <option value="S">Siang</option>
              <option value="K">Kelas Karyawan</option>
              <option value="I">Kelas Internasional</option>
            </select>
            <select
              v-else-if="filter.field == 'sub_jenis_sumbangan'"
              v-model="filter.value"
              class="form-select"
            >
              <option value="G1">Grade 1</option>
              <option value="G2">Grade 2</option>
              <option value="G3">Grade 3</option>
              <option value="G4">Grade 4</option>
              <option value="JTTG1">JTT Gelombang 1</option>
              <option value="JTTG2">JTT Gelombang 2</option>
              <option value="JP">Jalur Prestasi</option>
            </select>

            <select
              v-else-if="filter.field == 'sub_jenis_pendaftaran'"
              v-model="filter.value"
              class="form-select"
            >
              <option value="U">Umun</option>
              <option value="PAK">Alumni PAK</option>
              <option value="Kumbang">Alumni Kumbang</option>
              <option value="B2">Berdua Lebih Baik</option>
              <option value="Lainnya">Lainnya</option>
              <option value="K">Kelas Karyawan</option>
            </select>
            <input
              v-else
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
          </div>
          <div class="mt-2 mx-2 xl:mt-0">
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16"
              @click="onResetFilter"
            >
              RESET
            </button>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <SlideOver :open="createThnAjar" @close="closeCreateThnAjar">
      <template #title> Tambah Tahun Ajaran </template>
    </SlideOver>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { tblTabulator } from "@/utils/tabulator";
import router from "@/router";
import DeletingStatus from "../../../components/alerts/DeletingStatus";
import LoadingSpinner from "../../../components/loading-spinner/Main";
import SlideOver from "@/layouts/main/SlideOver";
import { master } from "@/services/models";
import { HTTPKEU } from "@/services/http";
export default defineComponent({
  components: {
    SlideOver,
    DeletingStatus,
    LoadingSpinner,
  },
  setup() {
    const store = useStore();
    const tableRef = ref();
    const tabulator = ref();
    const idJenis = ref();
    const alertSuccess = ref();
    const alertFailed = ref();
    const messageAlert = ref();
    const filter = reactive({
      field: "sub_jenis",
      type: "like",
      value: "",
    });
    const accessToken = store.getters["auth/accessToken"];

    const editRow = (e, cell) => {
      idJenis.value = cell.getData()?.id;
      alertSuccess.value = false;
      alertFailed.value = false;
      messageAlert.value = "";
      router.push({
        name: "edit-master-keuangan",
        params: { id: idJenis.value },
      });
    };

    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        {
          title: "Angkatan",
          field: "tahun",
          width: 100,
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Fakultas",
          field: "master_fakultas.nama",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Prodi",
          field: "master_prodi.nama",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Jenis",
          field: "master_jenis_biaya.keterangan",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
        },
        {
          title: "Sub Jenis",
          field: "sub_jenis",
          width: 110,
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          formatter(cell) {
            let dataFilter = [
              { kode: "P", nama: "Pagi" },
              { kode: "S", nama: "Sore" },
              { kode: "K", nama: "Kelas Karyawam" },
              { kode: "M", nama: "Kampus Merdeka" },
              { kode: "G1", nama: "Grade 1" },
              { kode: "G2", nama: "Grade 2" },
              { kode: "G3", nama: "Grade 3" },
              { kode: "G4", nama: "Grade 4" },
              { kode: "JTTG1", nama: "JTT Gel 1" },
              { kode: "JTTG2", nama: "JTT Gel 2" },
              { kode: "JP", nama: "Jalur Prestasi" },
            ];

            let valFil = dataFilter.filter((fil) => {
              return fil.kode == cell.getData().sub_jenis;
            });

            return valFil[0]?.nama;
          },
        },
        {
          title: "Biaya",
          field: "biaya",
          hozAlign: "right",
          vertAlign: "top",
          width: 160,
          print: true,
          download: true,
          formatter(cell) {
            let rupiahLocal = Intl.NumberFormat("id-ID");
            return "Rp. " + rupiahLocal.format(cell.getData().biaya);
          },
        },
        {
          title: "#",
          field: "id",
          responsive: 1,
          width: 70,
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let routeData = router.resolve({
              name: "edit-master-keuangan",
              params: { id: cell.getData().id },
            });

            return `
              <div>
                <a class="flex space-x-1 items-center btn btn-sm btn-warning" href="${routeData.href}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14px" fill="#000"><path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"/></svg> Edit
                </a>
              </div>`;
          },
          cellClick: editRow,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "master/biaya-kuliah",
        columns,
        true,
        null,
        { jenis_biaya: 2, id_fakultas: 1 }
      );
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "tahun";
      filter.type = "=";
      filter.value = "";
      onFilter();
    };
    onMounted(() => {
      initTabulator();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      accessToken,
      idJenis,
      alertSuccess,
      alertFailed,
      messageAlert,
    };
  },
  data() {
    return {
      deleting: false,
      editing: false,
      createThnAjar: false,
      insTahun: this.$store.state.main.currentTahunBiaya,
      insJenis: 2,
      insFakultas: 1,
      listTahun: [],
      faculties: [],
      listJenis: [],
      faculty: "0",
    };
  },
  computed: {
    loadingStatus() {
      return this.$store.state.main.loadingStatus;
    },
  },
  watch: {
    //update table if data changes
    /*tableData: {
      handler: function (newData) {
        this.tabulator.replaceData(newData);
      },
      deep: true,
    },*/
  },
  mounted() {
    this.gettahunlist();
    this.fetchFakultas();
    this.jenislist();
  },
  methods: {
    onReload() {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "master/biaya-kuliah",
        {},
        tblTabulator.ajaxConfig()
      );
    },
    setTahunBiaya() {
      this.$store.dispatch("main/currentTahunBiaya", this.insTahun);
      this.onReload();
      console.log(this.$store.state.main.currentTahunBiaya);
    },
    setJenisBiaya() {
      if (this.insJenis != null) {
        this.tabulator.setData(
          process.env.VUE_APP_KEUANGAN + "master/biaya-kuliah",
          { jenis_biaya: this.insJenis, id_fakultas: this.insFakultas },
          tblTabulator.ajaxConfig()
        );
      }
    },
    setFilFakultas() {
      if (this.insFakultas != null) {
        this.tabulator.setData(
          process.env.VUE_APP_KEUANGAN + "master/biaya-kuliah",
          { jenis_biaya: this.insJenis, id_fakultas: this.insFakultas },
          tblTabulator.ajaxConfig()
        );
      }
    },
    closeCreateThnAjar(status) {
      this.createThnAjar = status;
    },
    gettahunlist() {
      let nowYear = new Date().getFullYear();
      // eslint-disable-next-line for-direction
      for (let i = nowYear + 1; i > nowYear - 8; i--) {
        this.listTahun.push(i);
      }
    },

    fetchFakultas() {
      let that = this;
      this.faculties.push({
        id: "0",
        value: "0",
        text: "Pilih Fakultas",
        selected: false,
      });
      master.MasterFakultas.getAllActive().then((result) => {
        if (result?.status === 200) {
          result?.data?.data.forEach((item) => {
            let newoption = {
              id: item.id,
              value: item.id,
              text: item.nama,
              kode_pddikti: item.kode_pddikti,
              nama: item.nama,
              nama_alias: item.nama_alias,
              id_sp: item.id_sp,
              selected: that.current?.id === item.id,
            };
            if (newoption.selected === true) that.faculty = item.id;
            that.faculties.push(newoption);
          });
        }
      });
    },
    jenislist() {
      HTTPKEU.get("list/jenisbiaya").then((res) => {
        this.listJenis = [];
        let opt = {
          id: 0,
          value: "",
          sub: "",
          text: "Pilih Jenis Biaya",
          selected: true,
        };
        this.listJenis.push(opt);

        res.data.forEach((item) => {
          const opt = {
            id: item.id,
            value: item.id,
            sub: item.sub_jenis,
            text: `${item.nama}`,
            selected: false,
          };
          this.listJenis.push(opt);
        });
      });
    },
  },
});
</script>
